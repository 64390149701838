var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { backTo: "/", title: "Your Account" } }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "pt-4": "" } },
            [
              _c(
                "v-flex",
                { staticClass: "mb-5", attrs: { xs12: "", sm12: "" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "mb-5 text-xs-center",
                              attrs: { xs12: "", sm12: "" },
                            },
                            [
                              _c("Jazzicon", {
                                staticClass: "mb-3",
                                attrs: {
                                  address: _vm.currentUser.email,
                                  size: 60,
                                },
                              }),
                              _c(
                                "h2",
                                { staticClass: "headline mb-2 black--text" },
                                [_vm._v(_vm._s(_vm.currentUser.fullname))]
                              ),
                              _c("div", { staticClass: "subheading mb-5" }, [
                                _vm._v(_vm._s(_vm.currentUser.email)),
                              ]),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-3",
                                  attrs: {
                                    outline: "",
                                    color: "primary",
                                    large: "",
                                    block: "",
                                    depressed: "",
                                    to: "/password",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                Change Password\n              "
                                  ),
                                ]
                              ),
                              _vm.currentUser.isManager
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mb-3",
                                      attrs: {
                                        outline: "",
                                        color: "primary",
                                        large: "",
                                        block: "",
                                        depressed: "",
                                        to: "/fcra",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                Fair Credit Reporting Act\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-5",
                                  attrs: {
                                    outline: "",
                                    color: "primary",
                                    large: "",
                                    block: "",
                                    depressed: "",
                                    to: "/logout",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                Logout\n              "
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "body-1" }, [
                                _vm._v(
                                  "©" +
                                    _vm._s(new Date().getFullYear()) +
                                    " Tenant Evaluation LLC. "
                                ),
                                _c("span", { staticClass: "grey--text" }, [
                                  _vm._v(
                                    "v." +
                                      _vm._s(_vm.appVersion) +
                                      _vm._s(_vm.appMode)
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }