<template>
  <v-app>
    <tev-navbar backTo="/" title="Your Account" />
    <v-content>
      <v-layout row wrap pt-4>
        <v-flex xs12 sm12 class="mb-5">
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm12 class="mb-5 text-xs-center">
                <Jazzicon :address="currentUser.email" :size="60" class="mb-3" />
                <h2 class="headline mb-2 black--text">{{ currentUser.fullname }}</h2>
                <div class="subheading mb-5">{{ currentUser.email }}</div>
                <v-btn outline class="mb-3" color="primary" large block depressed to="/password">
                  Change Password
                </v-btn>
                <v-btn outline class="mb-3" color="primary" large block depressed to="/fcra" v-if="currentUser.isManager">
                  Fair Credit Reporting Act
                </v-btn>
                <v-btn outline class="mb-5" color="primary" large block depressed to="/logout">
                  Logout
                </v-btn>
                <div class="body-1">&copy;{{ new Date().getFullYear() }} Tenant Evaluation LLC. <span class="grey--text">v.{{ appVersion }}{{ appMode }}</span></div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>
<script>
import config from '@/config'

import Jazzicon from '../components/Jazzicon'
import {
  mapGetters
} from 'vuex'

export default {
  components: {
    Jazzicon
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      appVersion: 'appVersion'
    }),
    appMode() {
      switch (config.nodeEnv) {
        case 'production':
          return ''
        case 'staging':
          return '.qa-r2'
        default:
          return '.localhost'
      }
    }
  },
  created() {
    this.checkCurrentLogin()
  },
  updated() {
    this.checkCurrentLogin()
  },
  methods: {
    checkCurrentLogin() {
      const now = Math.floor(Date.now() / 1000);
      if (!this.currentUser || !this.currentUser.isLoggedIn || !localStorage.token || now >= this.currentUser.expire) {
        this.$router.push('/login?redirect=' + this.$route.path)
      }
      this.currentUser.isCompliance = this.currentUser.auth.includes('ROLE_L_CM')
      this.currentUser.isManager = this.currentUser.auth.includes('ROLE_L_PM')
      this.currentUser.isEditor = this.currentUser.auth.includes('ROLE_PEND_PROP_ED')
    }
  }
}
</script>

<style>
.addressIdenticon {
  width: 60px;
  height: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  overflow: hidden;
  background-color: #212121;
  margin: 0 auto;
}

label.addressIdenticon {
  margin-bottom: -0.9rem;
}

.addressIdenticon.big {
  width: 5rem;
  height: 5rem;
  padding: 0;
}

.addressIdenticon.med {
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
}

.addressIdenticon.small {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
}

.addressIdenticon.inline {
  display: inline-block;
}

.addressIdenticon.float {
  float: left;
  margin-right: @space-sm;
}

</style>